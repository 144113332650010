/* GLOBAL */

/* via https://piccalil.li/quick-tip/flow-utility */
.md > * + *,
blockquote > * + * {
  margin-top: var(--flow-space, 1em);
}

/* HEADINGS */

.md h1,
.md h2,
.md h3,
.md h4 {
  @apply font-display leading-snug;
}

.md h1 {
  @apply text-5xl;
}

.md h2 {
  @apply text-4xl;
}

.md h3 {
  @apply text-3xl italic;
}

.md h4 {
  @apply text-2xl;
}

/* LISTS */

.md ul,
.md ol {
  @apply block list-disc list-inside;
}

.md ol {
  @apply list-decimal;
}

.md li {
  @apply mb-1;
}

.md li > p {
  /* Fix for p inside li */
  @apply inline;
}

.md ul ul,
.md ol ul {
  @apply mt-1 ml-3;
}

.md ol ol,
.md ul ol {
  @apply mt-1 ml-3;
}

/* LINKS */

.md a {
  @apply underline;
}

/* BLOCKQUOTES */

.md blockquote {
  @apply bg-dark text-light dark:bg-light dark:text-dark my-4 mx-2 p-2;
}

.md blockquote {
  quotes: "\201C""\201D""\2018""\2019";
}

.md blockquote:before {
  @apply text-current;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.md blockquote p:first-child {
  @apply inline;
}

/* TABLES */
.md table {
  border-collapse: collapse;
}
.md tr {
  @apply border-b border-dark dark:border-light;
}
.md th,
.md td {
  @apply text-left p-1;
}
