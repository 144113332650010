/* Prevent scroll bar shift when navigation between pages */
@media screen and (min-width: 960px) {
    html {
        margin-left: calc(100vw - 100%);
        margin-right: 0;
    }
}

svg {
  fill: currentColor;
}

*:focus-visible {
  outline-color: currentColor;
  outline-offset: 6px;
}

input:focus-visible, textarea:focus-visible {
    outline-offset: -1px;
}

*:focus:not(:focus-visible) {
  outline: none;
}
